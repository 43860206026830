import React from 'react';
import * as ExcelJS from 'exceljs';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

const ExcelDetalleCohorte = ({ cohorte }) => {
    // Función para formatear fechas
    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(`${dateString}T00:00:00`);
        return date.toLocaleDateString('es-ES', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    // Función para obtener el rango de meses
    const getMonthRange = (startDate, endDate) => {
        if (!startDate || !endDate) return [];

        const start = new Date(`${startDate}T00:00:00`);
        const end = new Date(`${endDate}T00:00:00`);

        const months = [
            "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
            "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
        ];

        const result = [];
        const current = new Date(start);

        while (current <= end) {
            const monthName = months[current.getMonth()];
            if (!result.includes(monthName)) {
                result.push(monthName);
            }
            current.setMonth(current.getMonth() + 1);
        }

        return result;
    };

    const handleExport = async () => {
        if (!cohorte?.rawData?.JSONDataCohorte) {
            console.error("Datos de cohorte no disponibles");
            return;
        }

        const { cohorte: nombreCohorte, fechaInicio, fechaFinal, resumen, detalleSesiones } = cohorte.rawData.JSONDataCohorte;
        const fileName = `Detalle_Cohorte_${nombreCohorte.trim()}.xlsx`;
        const monthRange = getMonthRange(fechaInicio, fechaFinal);

        // Crear workbook y worksheet
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(`Cohorte ${nombreCohorte}`);

        // Configuración de columnas
        const columns = [
            { header: "# Registro", key: "numRegistro", width: 10 },
            { header: "# Sesión", key: "numSesion", width: 10 },
            { header: "Modalidad", key: "modalidad", width: 15 },
            { header: "Fecha", key: "fechaSesion", width: 15 },
            { header: "Periodo", key: "periodo", width: 20 },
            { header: "Módulo", key: "modulo", width: 15 },
            { header: "Subregión", key: "subregion", width: 25 },
            { header: "Municipio", key: "municipio", width: 25 },
            { header: "Asistentes", key: "asistentes", width: 12 },
            { header: "Mujeres", key: "mujeres", width: 10 },
            { header: "% Mujeres", key: "porcentajeMujeres", width: 12 },
            { header: "Hombres", key: "hombres", width: 10 },
            { header: "% Hombres", key: "porcentajeHombres", width: 12 },
            { header: "Pertenencia Étnica", key: "pertenenciaEtnica", width: 18 },
            { header: "% Étnico", key: "porcentajeEtnico", width: 12 }
        ];

        worksheet.columns = columns;

        // Título principal
        worksheet.mergeCells('A1:O1');
        const titleCell = worksheet.getCell('A1');
        titleCell.value = `DETALLE DE COHORTE - ${nombreCohorte}`;
        titleCell.font = { size: 16, bold: true, color: { argb: 'FFFFFF' } };
        titleCell.alignment = { vertical: 'middle', horizontal: 'center' };
        titleCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '042956' } };

        // Información general
        const infoRows = [
            ["Cohorte", nombreCohorte],
            ["Resumen", resumen],
            ["Fecha Inicio", formatDate(fechaInicio)],
            ["Fecha Fin", formatDate(fechaFinal)],
            ["Periodo", monthRange.join(", ")]
        ];

        infoRows.forEach(([label, value], i) => {
            const rowNum = 3 + i;

            // Celda de la etiqueta (columna A)
            const labelCell = worksheet.getCell(`A${rowNum}`);
            labelCell.value = label;
            labelCell.font = { bold: true };
            labelCell.alignment = { vertical: 'middle', horizontal: 'left' }; // Alineación izquierda

            // Celda del valor (columnas B a O)
            worksheet.mergeCells(`B${rowNum}:O${rowNum}`);
            const valueCell = worksheet.getCell(`B${rowNum}`);
            valueCell.value = Array.isArray(value) ? value.join(", ") : value;
            valueCell.alignment = { vertical: 'middle', horizontal: 'left', indent: 1 }; // Alineación izquierda

            if (label === "Resumen") {
                worksheet.getRow(rowNum).height = 30;
                valueCell.alignment.wrapText = true;
            }
        });

        // Encabezado de sesiones
        const sessionsStartRow = 3 + infoRows.length + 2;
        worksheet.mergeCells(`A${sessionsStartRow}:O${sessionsStartRow}`);

        const sessionsTitle = worksheet.getCell(`A${sessionsStartRow}`);
        sessionsTitle.value = "SESIONES";
        sessionsTitle.font = { bold: true, size: 14, color: { argb: 'FFFFFF' } };
        sessionsTitle.alignment = { vertical: 'middle', horizontal: 'center' };
        sessionsTitle.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '50ADF3' } };


        // Encabezados de tabla
        const headerRow = worksheet.addRow(columns.map(col => col.header), sessionsStartRow + 1);
        headerRow.font = { bold: true };
        headerRow.eachCell(cell => {
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'D0D8E0' } };
            cell.alignment = { vertical: 'middle', horizontal: 'left' }; 
        });

        // Datos de sesiones
        detalleSesiones.forEach((sesion, index) => {
            const rowNum = sessionsStartRow + 2 + index;
            const row = worksheet.addRow([
                sesion.numRegistro,
                sesion.numSesion,
                sesion.modalidadSesion || "Presencial",
                formatDate(sesion.fechaSesion),
                Array.isArray(sesion.periodo) ? sesion.periodo.join(", ") : sesion.periodo,
                sesion.modulo || "N/A",
                sesion.subregion?.nombre || "N/A",
                sesion.municipio?.nombre || "N/A",
                Number(sesion.asistentes) || 0,
                Number(sesion.mujeres) || 0,
                Number(sesion.porcentajeMujeres) / 100 || 0,
                Number(sesion.hombres) || 0,
                Number(sesion.porcentajeHombres) / 100 || 0,
                Number(sesion.pertenenciaEtnica) || 0,
                Number(sesion.porcentajeEtnico) / 100 || 0
            ]);

            // Formato numérico
            [1, 2, 9, 10, 12, 14].forEach(col => row.getCell(col).numFmt = '0');

            // Formato porcentaje
            [11, 13, 15].forEach(col => row.getCell(col).numFmt = '0.00%');

            // Formato fechas
            row.getCell(4).numFmt = 'dd/mm/yyyy';
        });


        // Descargar archivo
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
    };

    return (
        <Button
            type="default"
            icon={<DownloadOutlined />}
            onClick={handleExport}
            style={{ color: "green", marginLeft: "10px" }}
        />
    );
};

export default ExcelDetalleCohorte;