// excelCohortes.jsx
import React from 'react';
import * as ExcelJS from 'exceljs';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

const ExportToExcelCohortes = ({ data, indicadores }) => {




    const handleExport = async () => {
        const fileExtension = '.xlsx';
        const fileName = 'Resumen_Sesiones_Curso_UTF_COL_160' + fileExtension;

        // Crear un nuevo libro y una hoja de trabajo
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Resumen Sesiones');

        // Configurar la estructura de encabezados:
        // Total de columnas: 14 (A a N)
        // Fila 1: Título general
        worksheet.mergeCells('A1:W1');
        const titleCell = worksheet.getCell('A1');
        titleCell.value = 'RESUMEN DE SESIONES DEL CURSO - UTF_COL_160';
        titleCell.font = { size: 16, bold: true, color: { argb: 'FFFFFFFF' } };
        titleCell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        titleCell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '042956' },

        };

        // Fila 2: Encabezados de grupos
        // A y B son para "Cohorte" y "Resumen" (sin grupo, se fusionan verticalmente)
        worksheet.mergeCells('A2:A3'); // "Cohorte"
        worksheet.getCell('A2').value = 'Cohorte';
        const cohorteCell = worksheet.getCell('A2');

        cohorteCell.font = { size: 14, bold: true, color: { argb: 'FFFFFFFF' } };
        cohorteCell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        cohorteCell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '042956' } // Cambia el color según lo necesites
        };



        worksheet.mergeCells('B2:B3'); // "Resumen"
        worksheet.getCell('B2').value = 'Resumen';
        const resumenCell = worksheet.getCell('B2');
        resumenCell.value = 'Resumen';
        resumenCell.font = { size: 14, bold: true, color: { argb: 'FFFFFFFF' } };
        resumenCell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        resumenCell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '042956' } // Cambia el color según lo necesites
        };


        // "Sesiones" abarca de la columna C a F
        worksheet.mergeCells('C2:F3');
        const sesionesCell = worksheet.getCell('C2');
        sesionesCell.value = 'Sesiones';
        sesionesCell.font = { size: 14, bold: true, color: { argb: 'FFFFFFFF' } };
        sesionesCell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        sesionesCell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF0968C6' } // Cambia el color según lo necesites
        };

        // "Consolidado participación" abarca de F a J
        // Fusionar de F2 a J3 para el grupo "Consolidado participación"
        worksheet.mergeCells('G2:K3');
        const consCell = worksheet.getCell('G2');
        consCell.value = 'Consolidado participación';
        consCell.font = { size: 14, bold: true, color: { argb: 'FFFFFFFF' } };
        consCell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        consCell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF408CC8' } // Ajusta el color según necesites
        };



        // "Participación sesiones virtuales" abarca de la columna K a L
        worksheet.mergeCells('L2:O3');
        const partVirtualesCell = worksheet.getCell('L2');
        partVirtualesCell.value = 'Participación sesiones virtuales';
        partVirtualesCell.font = { size: 14, bold: true, color: { argb: 'FFFFFFFF' } };
        partVirtualesCell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        partVirtualesCell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF0B446F' } // Ajusta el color si lo deseas
        };

        // "Participación sesiones Autónomas" abarca M y N
        // "Participación sesiones Autónomas" abarca de la columna M a N
        worksheet.mergeCells('P2:S3');
        const partAutonomasCell = worksheet.getCell('P2');
        partAutonomasCell.value = 'Participación sesiones Autónomas';
        partAutonomasCell.font = { size: 14, bold: true, color: { argb: 'FFFFFFFF' } };
        partAutonomasCell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        partAutonomasCell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF50ADF3' } // Ajusta el color según lo necesites
        };

        worksheet.mergeCells('T2:W3');
        const partPrsencialesCell = worksheet.getCell('T2');
        partPrsencialesCell.value = 'Participación sesiones Presenciales';
        partPrsencialesCell.font = { size: 14, bold: true, color: { argb: 'FFFFFFFF' } };
        partPrsencialesCell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        partPrsencialesCell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF510D7F' } // Ajusta el color según lo necesites
        };

        // Fila 3: Subencabezados
        const subHeaders = [
            '', // Para "Cohorte" (ya fusionado)
            '', // Para "Resumen"
            'Total sesiones',
            'Presenciales',
            'Virtuales',
            'Autónomas',
            'Total Asistentes',
            'Hombres',
            'Mujeres',
            '% Hombres',
            '% Mujeres',
            'Asistentes Hombres',
            '% Hombres',
            'Asistentes Mujeres',
            '% Mujeres',
            'Asistentes Hombres',
            '% Hombres',
            'Asistentes Mujeres',
            '% Mujeres',
            'Asistentes Hombres',
            '% Hombres',
            'Asistentes Mujeres',
            '% Mujeres',
        ];
        const subHeaderRow = worksheet.addRow(subHeaders);
        subHeaderRow.eachCell((cell) => {
            cell.font = { size: 14, bold: false };
            cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            // Opcional: puedes aplicar un color de fondo para diferenciar los subencabezados
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFD0D8E0' },
            };
        });

        // Fijamos el ancho de las columnas (A a N)
        const columnWidths = [15, 25, 15, 15, 12, 15, 18, 12, 12, 12, 12, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22];
        worksheet.columns = columnWidths.map((width) => ({ width }));

        // Agregar los datos (a partir de la fila 4)
        data.forEach((record) => {
            const row = worksheet.addRow([
                record.cohorte,
                record.resumen,
                record.numSesiones,
                record.numSesionesPresenciales,
                record.numSesionesVirtuales,
                record.numSesionesAutonomas,
                record.totalAsistentes,
                record.asistentesHombres,
                record.asistentesMujeres,
                Number(record.porcentajeasistentesHombres) / 100,
                Number(record.porcentajeasistentesMujeres) / 100,
                record.asistentesHombresV,
                Number(record.porc_asistentesHombresV) / 100,
                record.asistentesMujeresV,
                Number(record.porc_asistentesMujeresV) / 100,
                record.asistentesHombresA,
                Number(record.porc_asistentesHombresA) / 100,
                record.asistentesMujeresA,
                Number(record.porc_asistentesMujeresA) / 100,


                record.asistentesHombresP,
                Number(record.porc_asistentesHombresP) / 100,
                record.asistentesMujeresP,
                Number(record.porc_asistentesMujeresP) / 100,




            ]);

            row.getCell(10).numFmt = '0.00%';
            row.getCell(11).numFmt = '0.00%';
            row.getCell(13).numFmt = '0.00%';
            row.getCell(15).numFmt = '0.00%';

            row.getCell(17).numFmt = '0.00%';
            row.getCell(19).numFmt = '0.00%';

            row.getCell(21).numFmt = '0.00%';
            row.getCell(23).numFmt = '0.00%';
        });


        // (Opcional) Agregar una fila de resumen global al final, si lo deseas:
        const summaryRow = worksheet.addRow([
            'Totales',
            '', // Para "Resumen"
            indicadores.numSesiones,
            indicadores.numSesionesPresenciales,
            indicadores.numSesionesVirtuales,
            indicadores.numSesionesAutonomas,
            indicadores.totalParticipantesPresenciales + indicadores.totalParticipantesVirtuales + indicadores.totalParticipantesAutonomas,
            indicadores.asistentesHombres,
            indicadores.asistentesMujeres,
            Number(indicadores.porc_Htot) / 100,
            Number(indicadores.porc_Mtot) / 100,

            indicadores.asistentesHombresV,
            (indicadores.asistentesHombresV / (indicadores.asistentesHombresV + indicadores.asistentesMujeresV)),//promedio ponderado
            indicadores.asistentesMujeresV,
            (indicadores.asistentesMujeresV / (indicadores.asistentesHombresV + indicadores.asistentesMujeresV)),
            indicadores.asistentesHombresA,
            (indicadores.asistentesHombresA / (indicadores.asistentesHombresA + indicadores.asistentesMujeresA)),
            indicadores.asistentesMujeresA,
            (indicadores.asistentesMujeresA / (indicadores.asistentesHombresA + indicadores.asistentesMujeresA)),


            indicadores.asistentesHombresP,
            (indicadores.asistentesHombresP / (indicadores.asistentesHombresP + indicadores.asistentesMujeresP)),
            indicadores.asistentesMujeresP,
            (indicadores.asistentesMujeresP / (indicadores.asistentesHombresP + indicadores.asistentesMujeresP)),

        ]);

        // Aplica el formato de porcentaje a las celdas correspondientes (índices 10 y 11)

        summaryRow.getCell(10).numFmt = '0.00%';
        summaryRow.getCell(11).numFmt = '0.00%';
        summaryRow.getCell(13).numFmt = '0.00%';

        summaryRow.getCell(15).numFmt = '0.00%';
        summaryRow.getCell(17).numFmt = '0.00%';
        summaryRow.getCell(19).numFmt = '0.00%';

        worksheet.mergeCells(summaryRow.number, 1, summaryRow.number, 2);
        summaryRow.getCell(1).alignment = { horizontal: 'right', vertical: 'middle' };

        summaryRow.font = { bold: true };
        summaryRow.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFD0D8E0' },
        };



        // Aplicar bordes a todas las celdas usadas (desde la fila 1 hasta la última)
        worksheet.eachRow({ includeEmpty: false }, (row) => {
            row.eachCell({ includeEmpty: false }, (cell) => {
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' },
                };

            });
        });

        // Guardar el archivo Excel y disparar la descarga
        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
        });
    };

    return (
        <Button
            type="primary"
            icon={<DownloadOutlined />}
            onClick={handleExport}
        >
            Resumen
        </Button>
    );
};

export default ExportToExcelCohortes;
