import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Button, Tooltip, Layout, Input } from 'antd';
import { ArrowRightOutlined, DownloadOutlined, ManOutlined, FileTextOutlined, SolutionOutlined, UserOutlined, WomanOutlined, FileDoneOutlined, FilePdfOutlined, CheckCircleOutlined } from '@ant-design/icons';
import getMenuCF from '../../../../services/Producto_3/Fort_Capacidades/Menu/menuCFService';
import ImageComponent from '../../../imageMapping/ImageComponentFortalecimiento';
import './MenuFortalecimiento.css';
import { useNavigate } from 'react-router-dom';
const MenuFortalecimiento = () => {

    const [menuData, setMenuData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();

    const iconMapping = {
        number_of_participants: { icon: <UserOutlined />, label: 'Participantes' },
        number_of_sessions: { icon: <FileTextOutlined />, label: 'Sesiones' },
        number_of_men: { icon: <ManOutlined />, label: 'Hombres', color: '#2DB7F6' },
        number_of_women: { icon: <WomanOutlined />, label: 'Mujeres', color: '#9C27B0' },
        number_of_cc_matriculas: { icon: <FileTextOutlined />, label: 'Matriculados' },
        number_of_cc_sesiones: { icon: <SolutionOutlined />, label: 'Sesiones' },
        number_of_cc_cohorte: { icon: <FileDoneOutlined />, label: 'Cohortes' },
        number_of_archives: { icon: <FileDoneOutlined />, label: 'Sesiones de acompañamiento' },
        number_of_registros: { icon: <FilePdfOutlined />, label: 'Organizaciones acompañadas' },
        number_of_registrosDHAA: { icon: <UserOutlined />, label: 'Participantes' },
        number_of_asistenciasDHAA: { icon: <CheckCircleOutlined />, label: 'Encuentros' }

    };


    useEffect(() => {
        const fetchMenuData = async () => {
            try {
                // Resuelve la promesa para obtener los datos
                const data = await getMenuCF();

                // Agregar la propiedad 'backgroundImage' a cada objeto


                setMenuData(data);
                setFilteredData(data);
            } catch (error) {
                console.error('Error al obtener el menú:', error);
            }
        };

        fetchMenuData();
    }, []);

    const handleSearch = (e) => {
        const value = e.target.value;
        setSearchTerm(value);
        if (value) {
            const filtered = menuData.filter(item => item.nombre.toLowerCase().includes(value.toLowerCase()));
            setFilteredData(filtered);
        } else {
            setFilteredData(menuData);
        }
    };


    const clearFilter = () => {
        setSearchTerm('');
        setFilteredData(menuData);
    };

    const handleCardClick = (id) => {
        if (id === 1) {
            navigate(`/cursoParticipacion`);
        } else if (id === 2) {
            navigate(`/cursoParticipacionCV`);
        } else if (id === 3) {
            navigate(`/acompanamientoOrg`);
        } else if (id === 4) {
            navigate(`/escuelaDHAATab`);
        }
    };

    return (
        <div className="container">
            <Layout>
                <div className="row mb-2 d-flex align-items-center">
                    <div className="col-md-8 linea_separador mb-2 d-flex align-items-center">
                        <div className="titulo_proyecto" style={{ flexBasis: '25%', flexGrow: 0 }}>
                            <p>PROYECTO UTF <br /> COL 160 COL</p>
                        </div>
                        <div className="objeto" style={{ flexBasis: '75%', flexGrow: 0 }}>
                            <p>
                                Implementar actividades de desarrollo económico, derecho humano a la alimentación y fortalecimiento de capacidades
                                institucionales, comunitarias y organizacionales que aporten al cierre de brechas y la transformación de los territorios PDET
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4 d-flex justify-content-center align-items-center flex-column">
                        <h2 className="text-center mb-2">Fortalecimiento de capacidades</h2>
                    </div>
                </div>
                <Row gutter={[16, 16]} style={{ marginBottom: 20 }}>
                    <Col span={24}>
                        <Input
                            placeholder="Buscar por nombre"
                            value={searchTerm}
                            onChange={handleSearch}
                            style={{ width: 400, height: 40, marginRight: 8 }}
                        />
                        <Button onClick={clearFilter} type="primary" style={{ height: 40 }}>
                            Limpiar Filtro
                        </Button>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    {console.log(filteredData)}
                    {filteredData.map((item, index) => (
                        <Col key={item.oid} xs={24} sm={12} md={8} lg={8} xl={6} className="mb-3">
                            <Card
                                className="card-menu h-100 redondear"
                                style={{
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    overflow: 'hidden'
                                }}
                            >
                                {/* Capa de fondo para toda la Card */}
                                <div style={{
                                     position: 'absolute',
                                     top: 70,
                                     left: 0,
                                     right: 0,
                                     bottom: 0,
                                     zIndex: 0,
                                     opacity: 1,
                                     transform: 'scale(1)', // Ajusta este valor (1 = 100%, 0.8 = 80%, 1.5 = 150%)
                                     transformOrigin: 'center center' // Punto desde donde se aplica el zoom
                                }}>
                                    <ImageComponent
                                        projectId={item.oid}
                                        style={{
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            overflow: 'hidden' // Cubre todo el espacio disponible
                                        }}
                                    />
                                </div>
                                <div style={{ position: 'relative', zIndex: 1 }}>
                                    {/* Header */}
                                    <div
                                        className={`card-header align-items-center text-center`}
                                        onClick={() => handleCardClick(item.oid)}
                                        style={{
                                            height: '60px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            padding: '35px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <h5 className="card-title fw-bold fs-5 m-0 text-header-color">
                                            {item.nombre}
                                        </h5>
                                    </div>
                                    <div
                                        className="contenidoFC"
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            flex: 1,
                                            minHeight: '150px',
                                            padding: '10px',
                                            position: 'relative'
                                        }}
                                    >
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                bottom: 0,
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center',
                                                zIndex: 0,
                                                width: '100%',
                                                height: '100%'
                                            }}
                                        >
                                            <div style={{ position: 'relative', zIndex: 1, width: '100%' }}>
                                                {Object.entries(item.children).length > 0 ? (
                                                    <Row gutter={16} style={{ marginTop: '10px', justifyContent: 'center' }}>
                                                        {Object.entries(item.children).map(([key, value]) => {
                                                            const { icon, label, color } = iconMapping[key] || {};
                                                            return (
                                                                icon && (
                                                                    <Col key={key} span={12} style={{ maxWidth: '130px', padding: '10px' }}>
                                                                        <Tooltip title={label}>
                                                                            <div
                                                                                className="text-center"
                                                                                style={{
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: 'center'
                                                                                }}
                                                                            >
                                                                                {React.cloneElement(icon, {
                                                                                    style: {
                                                                                        fontSize: '24px',
                                                                                        marginRight: '10px',
                                                                                        color: color || 'rgb(9 7 83)'
                                                                                    }
                                                                                })}
                                                                                <p style={{ fontSize: '18px', fontWeight: 'bold', margin: 0 }}>
                                                                                    {value}
                                                                                </p>
                                                                            </div>
                                                                            <p className="text-center" style={{ fontSize: '16px', margin: 0 }}>
                                                                                {label}
                                                                            </p>
                                                                        </Tooltip>
                                                                    </Col>
                                                                )
                                                            );
                                                        })}
                                                    </Row>
                                                ) : (
                                                    <p className="text-center" style={{ position: 'relative', zIndex: 1 }}>
                                                        No hay datos disponibles
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {/* Footer */}
                                    <div
                                        className="card-footer text-muted mt-auto"
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            marginTop: 'auto',
                                            padding: '10px',
                                            position: 'relative',
                                            zIndex: 2 // Asegura que el footer esté sobre el fondo
                                        }}
                                    >
                                        <Tooltip title="Ingresar">
                                            <Button
                                                className="m-2 btn-primary-lis"
                                                onClick={() => handleCardClick(item.oid)}
                                                style={{
                                                    background: 'none',
                                                    border: 'none',
                                                    color: 'inherit',
                                                    padding: 0,
                                                    font: 'inherit',
                                                    cursor: 'pointer'
                                                }}
                                                icon={<ArrowRightOutlined style={{ fontSize: 20, color: '#1890ff' }} />}
                                            />
                                        </Tooltip>
                                        <span className="separator" style={{ margin: '0 8px' }}>|</span>
                                        <Tooltip title="Descargar reporte">
                                            <Button
                                                className="m-2 btn-primary-lis"
                                                style={{
                                                    background: 'none',
                                                    border: 'none',
                                                    color: 'inherit',
                                                    padding: 0,
                                                    font: 'inherit',
                                                    cursor: 'pointer'
                                                }}
                                                icon={<DownloadOutlined style={{ fontSize: 20 }} />}
                                            />
                                        </Tooltip>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Layout>
        </div>
    );
};

export default MenuFortalecimiento;
