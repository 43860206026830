import React, { useEffect, useState } from "react";
import { Tabs, Form, Input, Select, Row, Col, Button, message } from "antd";
import { updateCaracterizacion, createCaracterizacion } from '../../../../services/Producto_3/Fort_Capacidades/Participantes/cursoFortalecimientoCaractService';

import { getDepartamentos, getMunicipios } from '../../../../services/Producto_3/Fort_Capacidades/Participantes/cursoFortalecimientoServiceDatos';
import cursoFortalecimientoCaraDataService from '../../../../services/Producto_3/Fort_Capacidades/Participantes/cursoFortalecimientoCaraDataService';

const { Option } = Select;

const FormCaracterizacion = ({ caracterizacionId, onSuccess, onPendingChanges }) => {
    console.log("caracterizacionId", caracterizacionId)

    const [form] = Form.useForm();
    const [activeTabKey, setActiveTabKey] = useState("1");
    const [, setFilterDepartamento] = useState(null);
    const [, setFilterMunicipio] = useState(null);
    const [filteredMunicipios, setFilteredMunicipios] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const [departamentos, setDepartamentos] = useState([]);
    const [isUpdateMode, setIsUpdateMode] = useState(false);
    const [, setIsFormChanged] = useState(false);

    const [periodicidad, setPeriodicidad] = useState([]);
    const [planeacion, setPlaneacion] = useState([]);
    const [tipoNorma, setTipoNorma] = useState([]);
    const [tipoInstancia, setTipoInstancia] = useState([]);
    const [nombreInstancia, setNombreInstancia] = useState([]);
    const [, setHasUnsavedChanges] = useState(false);

    useEffect(() => {
        if (caracterizacionId) {
            // Buscar el municipio seleccionado para asegurarse de que se obtenga el nombre
            const filtered = municipios.filter(municipio => municipio.oid_depto === caracterizacionId.oid_departamento);
            setFilteredMunicipios(filtered);


            // Asegúrate de que el id_municipio esté en el formato correcto (en este caso, solo el oid)
            const updatedData = {
                ...caracterizacionId,
                nombre_instancia: caracterizacionId.oid_nombre_instancia || null,
                id_departamento: caracterizacionId.oid_departamento || null,
                id_municipio: caracterizacionId.oid_municipio || null,
                instancia_tipo: caracterizacionId.oid_tipo_instancia || null,
                tipo_norma: caracterizacionId.oid_tipo_norma || null,
                planeacion: caracterizacionId.oid_planeacion || null,
                periodicidad: caracterizacionId.oid_periodicidad || null,
            };

            form.setFieldsValue(updatedData);
            setIsUpdateMode(true);
        } else {
            form.resetFields();
            setIsUpdateMode(false);
            setFilterDepartamento(null);
        }
    }, [caracterizacionId, form, municipios]);

    const handleTabChange = async (key) => {
        try {
            await form.validateFields();
            setActiveTabKey(key);
        } catch (err) {
            message.error('Por favor completa todos los campos requeridos.');
        }
    };

    const handleFormChange = () => {
        setIsFormChanged(true);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [
                    periodicidad,
                    planeacion,
                    tipoNorma,
                    tipoInstancia,
                    nombreInstancia
                ] = await Promise.all([
                    cursoFortalecimientoCaraDataService.getPeriodicidad(),
                    cursoFortalecimientoCaraDataService.getPlaneacion(),
                    cursoFortalecimientoCaraDataService.getTipoNorma(),
                    cursoFortalecimientoCaraDataService.getTipoInstancia(),
                    cursoFortalecimientoCaraDataService.getNombreInstancia()
                ]);

                setPeriodicidad(periodicidad);
                setPlaneacion(planeacion);
                setTipoNorma(tipoNorma);
                setTipoInstancia(tipoInstancia);
                setNombreInstancia(nombreInstancia);
            } catch (error) {
                console.error('Error al cargar los datos:', error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [departamentosData, municipiosData] = await Promise.all([
                    getDepartamentos(),
                    getMunicipios(),
                ]);
                setDepartamentos(departamentosData);
                setMunicipios(municipiosData);
            } catch (error) {
                console.error('Error al cargar los datos:', error);
            }
        };
        fetchData();
    }, []);

    const nextTab = async () => {
        try {
            await form.validateFields();
            setActiveTabKey("2");
        } catch (err) {
            message.error('Por favor completa todos los campos requeridos.');
        }
    };

    const prevTab = () => {
        setActiveTabKey("1");
    };

    const handleDepartamentoChange = (value) => {
        setFilterDepartamento(value);
        const filtered = municipios.filter(municipio => municipio.oid_depto === value);
        setFilteredMunicipios(filtered);
        setFilterMunicipio(null);
    };

    const handleValuesChange = () => {
        setHasUnsavedChanges(true);
        onPendingChanges(true);
    };

    const tabItems = [
        {
            key: "1",
            label: (
                <span>
                    Paso 1
                </span>
            ),
            children: (
                <div>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                name="id_departamento"
                                label="Departamento"
                                rules={[{ required: true, message: 'Por favor selecciona un departamento' }]}
                            >
                                <Select onChange={handleDepartamentoChange} placeholder="Selecciona un departamento">
                                    {departamentos.map(departamento => (
                                        <Option key={departamento.oid} value={departamento.oid}>
                                            {departamento.departamento}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="id_municipio"
                                label="Municipio"
                                rules={[{ required: true, message: 'Por favor selecciona un municipio' }]}
                            >
                                <Select placeholder="Selecciona un municipio">
                                    {filteredMunicipios.map(municipio => (
                                        <Option key={municipio.oid} value={municipio.oid}>
                                            {municipio.nombremunicipio}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="nombre_instancia"
                                label="Nombre de la instancia"
                                rules={[{ required: true, message: 'Por favor selecciona la instancia' }]}
                            >
                                <Select placeholder="Selecciona la instancia">
                                    {nombreInstancia.map(nomInstancia => (
                                        <Option key={nomInstancia.oid} value={nomInstancia.oid}>
                                            {nomInstancia.nombre}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                name="momento"
                                label="Momento"
                                rules={[{ required: true, message: 'Por favor seleccione el momento' }]}
                            >
                                <Select
                                    placeholder="Selecciona el momento"
                                >
                                    <Select.Option value={1}>Momento 1</Select.Option>
                                    <Select.Option value={2}>Momento 2</Select.Option>
                                </Select>
                            </Form.Item>

                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="instancia_muni"
                                label="¿El municipio cuenta con la instancia?"
                                rules={[{ required: true, message: "Por favor selecciona una opción" }]}
                            >
                                <Select
                                    placeholder="Selecciona una opción"
                                // Para verificar que el valor es un entero
                                >
                                    <Option value={1}>Sí</Option>
                                    <Option value={2}>No</Option>
                                    <Option value={3}>Sin Información</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="instancia_estado"
                                label="¿Está activa la instancia?"
                                rules={[{ required: true, message: "Por favor selecciona una opción" }]}
                            >
                                <Select
                                    placeholder="Selecciona una opción"
                                // Para verificar que el valor es un entero
                                >
                                    <Option value={1}>Sí</Option>
                                    <Option value={2}>No</Option>
                                    <Option value={3}>Sin Información</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                name="instancia_formalidad"
                                label="Estado de formalidad de la instancia"
                                rules={[{ required: true, message: "Por favor selecciona una opción" }]}
                            >
                                <Select
                                    placeholder="Selecciona una opción"
                                // Para verificar que el valor es un entero
                                >
                                    <Option value={1}>Sí</Option>
                                    <Option value={2}>No</Option>
                                    <Option value={3}>Sin Información</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="tipo_norma"
                                label="Tipo de norma"
                                rules={[{ required: true, message: 'Por favor seleccione el tipo de norma' }]}
                            >
                                <Select placeholder="Selecciona el tipo de norma">
                                    {tipoNorma.map(tipNor => (
                                        <Option key={tipNor.oid} value={tipNor.oid}>
                                            {tipNor.norma}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="numero_anual"
                                label="Número y año	"
                                rules={[{ required: true, message: "Por favor ingrese el número y el año" }]}
                            >
                                <Input placeholder="Ingresa el número y el año: 12 / 2024" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                name="trabajo_pilar"
                                label="¿Tiene prioridades de trabajo en los pilares 6 y 7?"
                                rules={[{ required: true, message: "Por favor selecciona una opción" }]}
                            >
                                <Select
                                    placeholder="Selecciona una opción"
                                // Para verificar que el valor es un entero
                                >
                                    <Option value={1}>Sí</Option>
                                    <Option value={2}>No</Option>
                                    <Option value={3}>Sin Información</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="instancia_reglamento"
                                label="¿Cuenta con reglamento interno la instancia? "
                                rules={[{ required: true, message: "Por favor selecciona una opción" }]}
                            >
                                <Select
                                    placeholder="Selecciona una opción"
                                // Para verificar que el valor es un entero
                                >
                                    <Option value={1}>Sí</Option>
                                    <Option value={2}>No</Option>
                                    <Option value={3}>Sin Información</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="planeacion"
                                label="Temas que se incluye en el instrumento de planeación"
                                rules={[{ required: true, message: 'Por favor selecciona un dato' }]}
                            >
                                <Select placeholder="Selecciona un dato">
                                    {planeacion.map(plan => (
                                        <Option key={plan.oid} value={plan.oid}>
                                            {plan.planeacion}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                name="instancia_planeacion"
                                label="¿Cuenta con un instrumentos de planeación que operativiza la instancia?"
                                rules={[{ required: true, message: "Por favor selecciona una opción" }]}
                            >
                                <Select
                                    placeholder="Selecciona una opción"
                                // Para verificar que el valor es un entero
                                >
                                    <Option value={1}>Sí</Option>
                                    <Option value={2}>No</Option>
                                    <Option value={3}>Sin Información</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="periodicidad"
                                label="Periodicidad en que se realiza el instrumento de planeación"
                                rules={[{ required: true, message: 'Por favor selecciona un dato' }]}
                            >
                                <Select placeholder="Selecciona un dato">
                                    {periodicidad.map(perio => (
                                        <Option key={perio.oid} value={perio.oid}>
                                            {perio.periodicidad}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="seguimiento"
                                label="¿Se hace seguimiento al instrumento de planeación?"
                                rules={[{ required: true, message: 'Por favor selecciona un dato' }]}
                            >
                                <Select
                                    placeholder="Selecciona una opción"
                                // Para verificar que el valor es un entero
                                >
                                    <Option value={1}>Sí</Option>
                                    <Option value={2}>No</Option>
                                    <Option value={3}>Sin Información</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="instancia_tipo"
                                label="Tipo de instancia"
                                rules={[{ required: true, message: 'Por favor seleccione el tipo de instancia' }]}
                            >
                                <Select placeholder="Selecciona el tipo de instancia">
                                    {tipoInstancia.map(tipoIns => (
                                        <Option key={tipoIns.oid} value={tipoIns.oid}>
                                            {tipoIns.instancia}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                </div>
            ),
        },
        {
            key: "2",
            label: (
                <span>
                    Paso 2
                </span>
            ),
            children: (
                <div>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                name="intercambio"
                                label="¿La instancia cuenta con procesos de aprendizaje e intercambio de conocimiento que cualifican a sus integrantes?"
                                rules={[{ required: true, message: 'Por favor selecciona un dato' }]}
                            >
                                <Select
                                    placeholder="Selecciona una opción"
                                // Para verificar que el valor es un entero
                                >
                                    <Option value={1}>Sí</Option>
                                    <Option value={2}>No</Option>
                                    <Option value={3}>Sin Información</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="politicas"
                                label="¿Las políticas públicas implementadas se alinean con las políticas nacionales relacionadas con pilar 6 y7?"
                                rules={[{ required: true, message: 'Por favor selecciona un dato' }]}
                            >
                                <Select
                                    placeholder="Selecciona una opción"
                                // Para verificar que el valor es un entero
                                >
                                    <Option value={1}>Sí</Option>
                                    <Option value={2}>No</Option>
                                    <Option value={3}>Sin Información</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="metas"
                                label="¿La instancia incorpora en su planeación objetivos, acciones, indicadores o metas de instrumentos de planeación territorial?"
                                rules={[{ required: true, message: 'Por favor selecciona un dato' }]}
                            >
                                <Select
                                    placeholder="Selecciona una opción"
                                // Para verificar que el valor es un entero
                                >
                                    <Option value={1}>Sí</Option>
                                    <Option value={2}>No</Option>
                                    <Option value={3}>Sin Información</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                name="voz_voto"
                                label="¿El acto normativo o reglamento de la instancia determina que los representantes sociales-comunitarios son integrantes permanentes con voz y voto?"
                                rules={[{ required: true, message: 'Por favor selecciona un dato' }]}
                            >
                                <Select
                                    placeholder="Selecciona una opción"
                                // Para verificar que el valor es un entero
                                >
                                    <Option value={1}>Sí</Option>
                                    <Option value={2}>No</Option>
                                    <Option value={3}>Sin Información</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="instrumento"
                                label="En caso de que si, indique el instrumento"
                                rules={[{ required: true, message: 'Por favor selecciona un dato' }]}
                            >
                                <Input placeholder="Ingresa el instrumento" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="porcentaje_voz_voto"
                                label="Porcentaje de la participación con voz y voto"
                                rules={[{ required: true, message: 'Por favor selecciona un dato' }]}
                            >
                                <Input placeholder="Ingresa el instrumento" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                name="acto_normativo"
                                label="Número de actores participante por acto normativo"
                                rules={[{ required: true, message: 'Por favor selecciona un dato' }]}
                            >
                                <Input placeholder="Ingresa el instrumento" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="comunicacion"
                                label="Trabajo articulado y comunicación fluída	"
                                rules={[{ required: true, message: 'Por favor selecciona un dato' }]}
                            >
                                <Select
                                    placeholder="Selecciona una opción"
                                // Para verificar que el valor es un entero
                                >
                                    <Option value={1}>Sí</Option>
                                    <Option value={2}>No</Option>
                                    <Option value={3}>Sin Información</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="participacion"
                                label="¿la instancia tiene mecanismos de para garantizar la participación?"
                                rules={[{ required: true, message: 'Por favor selecciona un dato' }]}
                            >
                                <Select
                                    placeholder="Selecciona una opción"
                                // Para verificar que el valor es un entero
                                >
                                    <Option value={1}>Sí</Option>
                                    <Option value={2}>No</Option>
                                    <Option value={3}>Sin Información</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                name="articulacion"
                                label="¿Hay articulación con otras instancias para la gestión?	"
                                rules={[{ required: true, message: 'Por favor selecciona un dato' }]}
                            >
                                <Select
                                    placeholder="Selecciona una opción"
                                // Para verificar que el valor es un entero
                                >
                                    <Option value={1}>Sí</Option>
                                    <Option value={2}>No</Option>
                                    <Option value={3}>Sin Información</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={16}>
                            <Form.Item
                                name="acompanamiento"
                                label="Necesidades sentidas de acompañamiento"
                                rules={[{ required: true, message: 'Por favor selecciona un dato' }]}
                            >
                                <Input placeholder="Ingresa el instrumento" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                name="mision1"
                                label="Lecciones Aprendidas (con base en los indicadores del momento 1) Misión 1"
                                rules={[{ required: true, message: 'Por favor selecciona un dato' }]}
                            >
                                <Input.TextArea placeholder="Ingresa el instrumento" rows={4} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="mision2"
                                label="Lecciones Aprendidas (con base en los indicadores del momento 1) Misión 2"
                                rules={[{ required: true, message: 'Por favor selecciona un dato' }]}
                            >
                                <Input.TextArea placeholder="Ingresa el instrumento" rows={4} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="mision3"
                                label="Lecciones Aprendidas (con base en los indicadores del momento 1) Misión 3"
                                rules={[{ required: true, message: 'Por favor selecciona un dato' }]}
                            >
                                <Input.TextArea placeholder="Ingresa el instrumento" rows={4} />
                            </Form.Item>
                        </Col>
                    </Row>
                </div>

            ),
        },
    ];
    const handleCreateOrUpdate = async () => {
        try {
            const values = await form.validateFields(); // Valida los campos del formulario
            if (isUpdateMode) {
                await updateCaracterizacion(caracterizacionId.oid_registro, values);
                message.success("Caracterización actualizada con éxito");
            } else {
                await createCaracterizacion(values);
                message.success("Caracterización creada con éxito");
            }
            form.resetFields(); // Limpia los campos del formulario
            onSuccess(); // Notifica al padre
            setActiveTabKey(1);
        } catch (err) {
            console.error("Error al guardar:", err);
        }
    };
    return (
        <div>
            <Form form={form} layout="vertical" onFieldsChange={handleFormChange} onValuesChange={handleValuesChange}>
                <Tabs
                    activeKey={activeTabKey}
                    onChange={handleTabChange}
                    items={tabItems}
                />
                <div style={{ marginTop: "16px", textAlign: "right" }}>
                    {activeTabKey === "2" && (
                        <>
                            <Button onClick={prevTab} style={{ marginRight: 8 }}>
                                Atrás
                            </Button>
                            <Button
                                type="primary"
                                onClick={handleCreateOrUpdate}
                            >
                                {isUpdateMode ? "Actualizar" : "Crear"}
                            </Button>
                        </>
                    )}
                    {activeTabKey === "1" && (
                        <Button type="primary" onClick={nextTab}>
                            Siguiente
                        </Button>
                    )}
                </div>
            </Form>
        </div>
    );
};

export default FormCaracterizacion;
